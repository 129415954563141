import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { AuthService, IoService } from '@nest/common-core-frontend';
import { FavoritesMenuComponent, NotificationPanelComponent, SearchBoxComponent } from '@nest/desktop-frontend-shell';
import { NotificationsStore } from '@nest/domain-activities-frontend-entity-presentation';
import { ButtonComponent } from '@nest/uikit-button-ui';
import { injectDialogs } from '@nest/uikit-dialogs-ui';
import { DROPDOWN_IMPORTS, DROPDOWN_MENU_IMPORTS } from '@nest/uikit-dropdown-ui';
import { ICON_IMPORTS } from '@nest/uikit-icon-ui';
import { LAYOUT_CONTAINER_IMPORTS } from '@nest/uikit-layout-ui';
import { provideIcons } from '@ng-icons/core';
import {
	iconoirAppWindow,
	iconoirBell,
	iconoirBookStack,
	iconoirBox3dPoint,
	iconoirCheck,
	iconoirDeliveryTruck,
	iconoirKanbanBoard,
	iconoirLogOut,
	iconoirUser
} from '@ng-icons/iconoir';
import { ApplicationService } from '../../services/application.service';

interface MenuItem {
	label: string;
	path?: string;
	icon?: string;
	queryParams?: any;
	dialog?: { componentType: any; data?: any };
	children?: MenuItem[];
}

type ApplicationSize = {
	label: string;
	value: string;
	size: string;
};

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styles: `
		:host {
			@apply w-screen h-screen overflow-hidden flex flex-col;
		}
	`,
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		DROPDOWN_IMPORTS,
		FavoritesMenuComponent,
		RouterLink,
		ButtonComponent,
		RouterOutlet,
		NotificationPanelComponent,
		DROPDOWN_MENU_IMPORTS,
		ICON_IMPORTS,
		LAYOUT_CONTAINER_IMPORTS
	],
	providers: [
		provideIcons({ iconoirUser, iconoirBell, iconoirLogOut, iconoirCheck, iconoirBox3dPoint, iconoirKanbanBoard, iconoirDeliveryTruck, iconoirAppWindow, iconoirBookStack })
	]
})
export class MainLayoutComponent {
	router = inject(Router);
	auth = inject(AuthService);
	app = inject(ApplicationService);
	notifications = inject(NotificationsStore);
	dialogs = injectDialogs();
	io = inject(IoService);

	selectedMenuItem: MenuItem;
	unreadNotificationCount: number;
	sizes = [
		{ label: 'SM', value: 'sm', size: '11px' },
		{ label: 'MD', value: 'md', size: '12.8px' },
		{ label: 'LG', value: 'lg', size: '14px' }
	];

	currentSize = signal(this.sizes[1]);
	notificationPanelOpen = signal(false);

	onMenuItemClick(item: MenuItem) {
		this.router.navigate([item.path], {
			queryParams: item.queryParams
		});
	}

	changeOrganization() {
		this.auth.setOrganizationId(null);
		this.router.navigate(['/']);
	}

	changeSizing(size: ApplicationSize) {
		this.currentSize.set(size);
		document.documentElement.style.setProperty('--font-size', size.size);
	}

	logout() {
		this.auth.logout();
		this.router.navigate(['/login']);
	}

	toggleNotificationPanel() {
		this.notificationPanelOpen.update((value) => !value);
	}

	openSearch() {
		this.dialogs.open(SearchBoxComponent);
	}
}
